<template>
  <div id="app">
    <div class="container">
      <div>
        <div>
          <el-form label-width="80px" :model="contentTypeList">

            <el-form-item label="指令类型：" required v-model="contentTypeList" label-width="100" size="small" style="font-weight:600">
              <el-select v-model="contentTypeList.commandType" filterable placeholder="请选择指令类型" @change="changeVal" style="width:35%" clearable>
                <el-option v-for="(item,index) in ['AEP-LWM2M-透传','AEP-LWM2M-非透传','硬件接收']" :key="index" :label="item" :value="item">
                </el-option>
              </el-select>
              <!-- <el-radio-group v-model="contentTypeList.commandType" @change="changeVal">
                <el-radio-button v-for="(item,index) in ['AEP-LWM2M-透传','AEP-LWM2M-非透传','硬件接收']" :key="index" :label="item" style="margin: 0 5px"></el-radio-button>
              </el-radio-group> -->
              <!-- 添加指令 -->
              <el-button type="success" style="float:right" size="mini" @click="addInfo" v-if="contentTypeList.commandType!=''">添加</el-button>
            </el-form-item>

            <!-- v-if="contentTypeList.commandType.indexOf('AEP')!=-1" -->
            <div class="content" v-if="contentTypeList.commandType!=''">
              <!-- <div class="content" v-if="contentTypeList.commandType.indexOf('AEP')!=-1"> -->
              <el-form-item label="内容标识：" required label-width="100" class="content-item" v-if="contentTypeList.commandType!='硬件接收'">
                <el-input class="inpt" v-model="contentTypeList.contentType" placeholder="请输入内容标识"></el-input>
              </el-form-item>
              <el-form-item label="指令名称：" required label-width="100" class="content-item" :style="contentTypeList.commandType=='硬件接收'?'width:100%':''">
                <el-input class="inpt" v-model="contentTypeList.commandName" placeholder="请输入指令名称" :style="contentTypeList.commandType=='硬件接收'?'width:85%':''"></el-input>
              </el-form-item>
              <el-form-item label="参数名称：" required label-width="100" class="content-item">
                <el-input class="inpt flag" v-model="contentTypeList.paramName" placeholder="请输入参数名称" :disabled="disInp1" clearable></el-input>
                <el-button @click="add('param',contentTypeList.paramName)" :disabled="forbid">+</el-button>
                <div class="infoList">
                  <span class="infoItem" v-for="(item,index) in paramList" :key="index">
                    <span> {{item}} </span>
                    <button size="mini" @click="deleteOpt(index,'param')">删除</button>
                  </span>
                </div>
              </el-form-item>
              <el-form-item label="指令内容：" required label-width="100" class="content-item">
                <el-input class="inpt flag" v-model="contentTypeList.paramValue" placeholder="请输入指令名称" :disabled="disInp2" clearable></el-input>
                <el-button @click="add('order',contentTypeList.paramValue)" :disabled="forbid">+</el-button>
                <div class="infoList">
                  <span class="infoItem" v-for="(item,index) in orderList" :key="index">
                    <span> {{item}} </span>
                    <button size="mini" @click="deleteOpt(index,'order')">删除</button>
                  </span>
                </div>
              </el-form-item>

              <!-- 已添加的信息展示 -->
              <div class="displayInfo">
                <!-- @click="editInfo(item)" -->
                <div v-for="(item,index) in contentTypeArrList" :key="index" class="display-item">
                  <div>
                    <span><b>指令类型：</b><span style="border-bottom:1px dashed #999"></span>{{item.commandType}}</span></span>
                    <span v-if="item.commandType!='硬件接收'"><b>内容标识：</b><span style="border-bottom:1px dashed #999">{{item.contentType}}</span></span>
                    <span><b>指令名称：</b><span style="border-bottom:1px dashed #999">{{item.commandName}}</span></span>
                    <span v-if="item.paramName!=''"><b>参数名称：</b><span style="border-bottom:1px dashed #999">{{item.paramName.split('&').toString()}}</span></span>
                    <span v-if="item.paramValue!=''"><b>指令内容：</b><span style="border-bottom:1px dashed #999">{{item.paramValue.split('&').toString()}}</span></span>
                  </div>
                  <el-button class="btn" type="danger" size="mini" @click="removeInfo(index)">删除</el-button>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-for="item in deviceCommands" :key="item.id" class="info-box" v-if="deviceCommands.length!=0">
                <br>
                <div><span>指令类型：</span> {{item.commandType}}</div>
                <div><span>内容标识：</span> {{item.contentType}}</div>
                <div><span>指令名称：</span> {{item.commandName}}</div>
                <div><span>参数名称：</span> {{item.paramName}}</div>
                <div><span>指令内容：</span> {{item.paramValue}}</div>
              </div>
              <div class="tipInfo" v-if="deviceCommands.length==0">
                该设备类型尚未添加指令
              </div>

            </div>
          </el-form>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'OperationalOrder',
  // [设备类型信息， 设备类型指令信息]
  //  控制弹框显示, "showOrder"，
  props: ["deviceType", "showOrder", "deviceCommands"],
  data() {
    return {
      disInp1: false, // 控制参数名称禁用
      disInp2: false, // 控制指令名称禁用
      forbid: false,  // 按钮禁用（参数名称、指令内容）

      contentTypeArrList: [],

      contentTypeList: {
        commandType: "",//指令下发类型
        contentType: "",//指令下发内容标识
        commandName: "",//指令名称
        paramName: "",//参数名称
        paramValue: "",//指令内容
      },//指令下发内容标识集合

      paramList: [],  // 参数名称
      orderList: [],  // 指令内容

    }
  },

  created() {
    // console.log(this.showOrder);
    // console.log(this.deviceType);
    console.log(this.deviceCommands);
    this.contentTypeArrList = this.deviceCommands
  },
  watch: {
    // 硬件接收的参数名和指令只能填一个
    contentTypeList: {
      handler: function (a) {
        if (this.contentTypeList.commandType == "硬件接收") {
          // let _this = this
          if (a.paramName != '') {
            this.disInp2 = true
          } else if (a.paramValue != '') {
            this.disInp1 = true
          } else {
            this.disInp1 = false
            this.disInp2 = false
          }
        } else {
          this.disInp1 = false
          this.disInp2 = false
        }
      },
      deep: true
    }
  },

  methods: {
    // 选择指令类型
    changeVal(data) {
      this.cleatInfo()
      // this.contentTypeArrList = []
      this.showOrder = true
      // 如果是硬件接收，禁用添加按钮
      if (data == "硬件接收") {
        this.forbid = true
      } else {
        this.forbid = false
      }
      this.$emit('cleat-list', this.showOrder)
    },

    // editInfo(data) {
    //   let data = data.slice
    //   this.paramList = data.paramName.split('&')
    //   this.orderList = data.paramValue.split('&')
    //   console.log(this.paramList);
    //   console.log(this.orderList);
    //   data.paramName = ''
    //   data.paramValue = ''
    //   console.log(data);
    //   this.contentTypeList = data
    // },

    // 删除参数名称/指令内容添加的数据
    deleteOpt(index, msg) {
      // if (msg == "param") {
      this.paramList.splice(index, 1)
      // } else if (msg == "order") {
      this.orderList.splice(index, 1)

      // }
    },


    // 添加参数名称/指令内容
    add(msg, value) {
      if (msg == "param") {
        if (value == '') {
          this.$message.warning("参数名称不能为空！")
        } else {
          this.paramList.push(value)
          this.contentTypeList.paramName = ''
        }
      } else if (msg == "order") {
        if (value == '') {
          this.$message.warning("指令内容不能为空！")
        } else {
          this.orderList.push(value)
          this.contentTypeList.paramValue = ''
        }
      }
    },
    // 删除添加的信息
    removeInfo(index) {
      this.contentTypeArrList.splice(index, 1)
      console.log(this.contentTypeArrList);

      this.$emit('order-list', this.contentTypeArrList)

    },


    addInfo() {
      // 硬件接收
      if (this.contentTypeList.commandType == '硬件接收') {
        // 内容标识非空验证
        if (this.contentTypeList.commandName == '') {
          this.$message.warning("指令名称不能为空！")
        } else if (this.contentTypeList.paramValue == '' && this.contentTypeList.paramName == '') {
          this.$message.warning("请选填参数名称或指令内容！")
        } else {
          let obj = {}
          obj.commandType = this.contentTypeList.commandType    // 指令类型
          // 无参
          if (this.contentTypeList.paramValue != '') {
            obj.contentType = "commandNoData"    // 内容标识
          }
          // 有参
          if (this.contentTypeList.paramName != '') {
            obj.contentType = "commandData"    // 内容标识
          }
          obj.commandName = this.contentTypeList.commandName    // 指令名称
          obj.paramName = this.contentTypeList.paramName          // 参数名称
          obj.paramValue = this.contentTypeList.paramValue           // 指令内容
          obj.typeId = this.deviceType.id     // 设备类型id
          this.contentTypeArrList.push(obj)
          this.cleatInfo()
          this.$emit('order-list', this.contentTypeArrList)
        }

      } else {
        // 非硬件接收，非空验证
        if (this.contentTypeList.contentType == '' ||     // 内容标识
          this.contentTypeList.commandName == '' ||      // 指令名称
          this.paramList.length == 0 ||                 // 参数名称
          this.orderList.length == 0                   // 指令内容
        ) {
          this.$message.warning("必填项不能为空！");
        } else if (this.paramList.length != this.orderList.length) {
          this.$message.warning("确保参数名称数量与指令名称数量保持一致！");
        } else {
          let obj = {}
          obj.commandType = this.contentTypeList.commandType    // 指令类型
          obj.contentType = this.contentTypeList.contentType    // 内容标识
          obj.commandName = this.contentTypeList.commandName    // 指令名称
          obj.paramName = this.paramList.join('&')            // 参数名称
          obj.paramValue = this.orderList.join('&')           // 指令内容
          obj.typeId = this.deviceType.id     // 设备类型id

          this.contentTypeArrList.push(obj)
          console.log(this.contentTypeArrList);
          this.cleatInfo()

          this.$emit('order-list', this.contentTypeArrList)

        }
      }
    },

    // 清空内容
    cleatInfo() {
      this.contentTypeList.contentType = ""     //指令下发内容标识
      this.contentTypeList.commandName = ""     //指令名称
      this.contentTypeList.paramName = ""     //参数名称
      this.contentTypeList.paramValue = ""      //指令内容
      this.paramList = []  // 参数名称
      this.orderList = []  // 指令内容
    },

  },



}
</script>

<style lang="scss" scoped>
.content {
  // padding: 10px;
  display: flex;
  flex-wrap: wrap;

  .content-item {
    font-weight: 600;
    width: 50%;

    .inpt {
      width: 70%;
    }
    .flag {
      width: 56%;
      margin: 0 10px 0 0;
    }
    .infoList {
      box-sizing: border-box;
      width: 75%;
      margin: 10px auto 0;

      padding-left: 25px;

      .infoItem {
        display: inline-block;
        margin: 0 10px;
        span {
          display: inline-block;
        }
        button {
          outline: none;
          border: none;
          margin: 0 10px;
          color: red;
          font-size: 14px;
        }
      }
    }
  }

  .displayInfo {
    width: 100%;

    .display-item {
      margin: 10px 0;
      width: 100%;
      box-shadow: 1px 3px 3px #ccc;
      border-radius: 5px;

      // span:nth-child(1) {
      //   color: black;
      //   margin: 0;
      // }
      display: flex;

      position: relative;

      div {
        width: 92%;
        span {
          font-size: 16px;
          display: inline-block;
          margin: 5px;
        }
      }
      .btn {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 5px;
        width: 8%;
        display: inline-block;
      }
    }
    .display-item:hover {
      cursor: pointer;
      background: rgba(208, 238, 241, 0.5);
    }
  }
}

.tipInfo {
  font-size: 18px;
  width: 100%;
  text-align: center;
  user-select: none;
  line-height: 300px;
  letter-spacing: 2px;
  color: rgb(129, 129, 134);
}

.info-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 10px;
  background: rgb(239, 248, 250);
  margin: 10px 0;
  div {
    font-size: 16px;
    display: inline-block;
    padding: 10px 20px;
    span {
      font-weight: 600;
      display: inline-block;
    }
  }
}
</style>