import request from '@/../utils/request'
const api_name = '/iotechserver/deviceCommand'

export default {
  // 通过设备id获取设备指令信息
  getCommandByDeviceId(deviceId, commandName) {
    return request({
      url: `${api_name}/getCommandByDeviceId/${deviceId}/${commandName}`,
      method: 'get',
    })
  },

  // 通过设备类型id获取设备指令信息
  getCommandByTypeId(typeId, commandName) {
    return request({
      url: `${api_name}/getCommandByTypeId/${typeId}/${commandName}`,
      method: 'get'
    })
  },

  // 修改设备类型的相关指令
  updateCommand(contentTypeList) {
    return request({
      url: `${api_name}/updateCommand`,
      method: 'post',
      data: {
        deviceCommandList: contentTypeList
      }
    })
  },

  // 删除设备类型的相关指令
  deleteCommand(typeId) {
    return request({
      url: `${api_name}/deleteCommand/${typeId}`,
      method: 'get'
    })
  }


}