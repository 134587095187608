<template>
  <div id="app" >
    <div style="position: relative;display:flex;justify-content: center;align-items: center;">
      <div class="item">
          <el-input @keyup.enter.native="getDeviceTypeList()" placeholder="请输入设备类型名" v-model="deviceTypeInfo.name"></el-input>
      </div>
      <div class="item">
          <el-button type="primary" size="default" @click="getDeviceTypeList()">查询</el-button>
      </div>
      <div class="item">
          <el-button type="success" size="default" @click="handelAdd" v-if="userRightList.addJuris=='1'">添加设备类型</el-button>
      </div>
      <div class="item" style="margin-left:auto;">
        <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
            <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
            </el-option>
          </el-select>
      </div>
    </div>
      <div>
        <el-table v-loading="loading" :data="deviceTypeList" style="width: 100%" highlight-current-row>
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" class="demo-table-expand">
                <el-form-item label="静态属性：" prop="value" style="margin-right: 50px">
                  <span v-for="item in props.row.properties" :key="item">{{ item }}
                  </span>
                </el-form-item>
                <el-form-item label="动态属性：" prop="value" style="margin-right: 50px">
                  <span v-for="item in props.row.val" :key="item">{{ item }}
                  </span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
          <el-table-column v-if="tableTitle.includes('设备类型编号')" prop="id" label="设备类型编号" align="center"></el-table-column>
          <el-table-column v-if="tableTitle.includes('设备类型')" prop="name" label="设备类型" align="center"></el-table-column>
          <el-table-column fixed="right" label="操作" width="250" align="center" v-if="userRightList.updateJuris=='1'">>
            <template slot-scope="scope">
              <el-button @click="instruct(scope.row)" type="success" size="small" v-if="userRightList.updateJuris=='1'">指令</el-button>
              <el-button @click="handleEdit(scope.row)" type="primary" size="small" v-if="userRightList.updateJuris=='1'">编辑</el-button>
              <el-button @click="handleRemove(scope.row.id)" type="danger" size="small" v-if="userRightList.delJuris=='1'">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    <el-dialog title="指令操作" :visible.sync="showOrder" width="50%" style="background:rgba(0,0,0,0.5)" @close="closeShowOrder" :close-on-click-modal="false">
      <div class="order">
        <!-- @cleat-list="cleatList" -->
        <OperationalOrder :deviceType="deviceTypeInfoList" :showOrder="showOrder" :deviceCommands="deviceCommands" @order-list="orderList" :key="timer" />
      </div>
      <div slot="footer">
        <el-button @click="closeShowOrder">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
        <!-- :disabled="submitTip" -->
      </div>
    </el-dialog>

    <!-- 添加 / 编辑设备类型 -->
    <el-dialog :title="flag==true?deviceTypeTitle:'添加设备类型'" :visible.sync="dialogVisible" width="50%" @close="clearDeviceTypeInfo" :close-on-click-modal="false" style="background:rgba(0,0,0,0.5)">
      <div style="height:500px;overflow:scroll;scrollbar-width: none;">
        <el-form :model="deviceTypeInfo" ref="form" label-width="100px" :inline="false" size="normal" :rules="rules">
          <el-form-item label="ID" prop="id">
            <!-- :disabled="addOrEditFlag == 'edit'" -->
            <el-input v-model="deviceTypeInfo.id" :disabled="addOrEditFlag == 'edit'"></el-input>
          </el-form-item>
          <el-form-item label="设备类型" prop="name">
            <el-input v-model="deviceTypeInfo.name"></el-input>
          </el-form-item>
          <el-form-item label="静态属性" prop="properties">
            <span v-for="item in deviceTypeInfo.properties" :key="item">{{ item }}-</span><br />
            <el-row :gutter="20">
              <el-col :span="12" :offset="0">
                <el-input v-model="propertiesNameTemp" placeholder="静态属性值：proprities"></el-input>
              </el-col>
              <el-col :span="12" :offset="0">
                <!-- 增加和减一按钮 -->
                <el-button type="primary" size="mini" icon="el-icon-plus" @click="addToProperties" circle :disabled="!propertiesNameTemp"></el-button>
                <el-button type="danger" size="mini" icon="el-icon-minus" @click="removeLastOneFromProperties" circle></el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="动态属性" prop="val">
            <span v-for="item in deviceTypeInfo.val" :key="item">{{ item }}-</span><br />
            <el-row :gutter="20">
              <el-col :span="12" :offset="0">
                <el-input v-model="valNameTemp" placeholder="动态属性值：value"></el-input>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-button type="primary" size="mini" icon="el-icon-plus" @click="addToVal" :disabled="!valNameTemp" circle></el-button>
                <el-button type="danger" size="mini" icon="el-icon-minus" @click="removeLastOneFromVal" circle></el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <!-- <button @click="checkLinkage" class="linkageBtn">添加联动类型</button> -->
          <div>
            <el-form-item style="width:200px" label="添加联动类型">
              <el-switch v-model="showLinkage" active-color="#13ce66" inactive-color="#bbb"></el-switch>
            </el-form-item>
          </div>

          <div v-if="showLinkage">
            <el-form-item label="联动类型">
              <span v-for="(item, index) in conditionsTypeList" :key="index">{{ item }}-</span><br />
              <el-row :gutter="20">
                <el-col :span="12" :offset="0">
                  <el-input v-model="conditionsType" placeholder="请填写联动类型"></el-input>
                </el-col>
                <el-col :span="12" :offset="0">
                  <el-button type="primary" size="mini" icon="el-icon-plus" @click="addLinkage" circle></el-button>
                  <el-button type="danger" size="mini" icon="el-icon-minus" @click="removeLinkage" circle></el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </div>

          <el-form-item label="图标" prop="val">
            <input id="deviceTypeIcon" type="file" ref="uploadInput" @change="fileChange" />
            <span>上传图标只能是 JPG、PNG或SVG 格式,并且大小不超过1M</span>
            <img v-if="imgFlag" :src="deviceTypeInfo.deviceTypeIcon" style="width: 50px" />
            <img v-else :src="baseUrl + deviceTypeInfo.deviceTypeIcon" style="width: 50px" />
          </el-form-item>

          <el-form-item label="产品ID" prop="productId">
            <el-input v-model="deviceTypeInfo.productId"></el-input>
          </el-form-item>

          <el-form-item label="产品密钥" prop="masterAPIkey">
            <el-input v-model="deviceTypeInfo.masterAPIkey"></el-input>
          </el-form-item>

        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
         <el-button @click="cancleDialog">取 消</el-button>  <el-button type="primary" @click="addOrEditDeviceType">确 定</el-button>
      </div>

    </el-dialog>
    <el-pagination :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" style="padding-top: 10px;  text-align: center" @current-change="getDeviceTypeList"></el-pagination>

  </div>
</template>

<script>
import deviceType from "../../api/manageApi/DeviceTypeManagement";
import BaseData from "@/assets/config/BaseData.js";
// 操作指令组件
import OperationalOrder from '@/components/manage/OperationalOrder'
import OperationalOrders from '@/api/managementApi/OperationalOrder'

// 查权限
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'
// 查账号
import user from "@/api/manageApi/User";
export default {
  components: {
    OperationalOrder,
  },
  data() {
    return {
      showLinkage: false,
      conditionsType: '',
      conditionsTypeList: [],


      tableTitleList: [{
        id: 0,
        title: '设备类型编号',
      }, {
        id: 1,
        title: '设备类型',
      }],
      tableTitle: ['设备类型编号', '设备类型'],

      timer: '',  // 刷新子组件传参
      showOrder: false,
      showCommand: false,
      flag: false,
      //动态属性和静态属性
      propertiesNameTemp: "",
      valNameTemp: "",
      //分页相关数据
      current: 1, //当前页
      limit: 10, //每页显示条数
      total: 0, //数据总数

      //图片虚拟路径前缀，用于访问服务器以获取图片
      baseUrl: BaseData.baseUrl,

      //页面数据
      deviceTypeList: [], //设备类型集合
      deviceTypeInfo: {
        //当前编辑或添加的设备信息
        id: "",
        name: "",
        properties: [],
        val: [],
        deviceTypeIcon: "",
        productId: "",
        masterAPIkey: ""
      },
      deviceTypeTitle: "",//设备类型标题显示

      //表单验证
      rules: {
        id: [
          {
            required: true,
            message: "请输入设备类型ID",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入设备类型名",
            trigger: "blur",
          },
        ],
        properties: [
          {
            required: true,
            message: "请输入设备类型静态属性",
            trigger: "blur",
          },
        ],
        val: [
          {
            required: true,
            message: "请输入设备类型动态属性",
            trigger: "blur",
          },
        ],

      },
      deviceTypeQuery: {
        //模糊查询条件对象
        name: "",
      },
      propertiesNameTemp: "", //追加静态属性
      valNameTemp: "", //追加动态属性

      //标志位
      dialogVisible: false, //添加、编辑弹框的标志位
      addOrEditFlag: "add", //添加或者编辑的标志位  add是添加   edit是编辑
      imgFlag: false,
      loading: true,

      //上传图片
      file: {},

      deviceTypeInfoList: [], // 设备类型信息

      // submitTip: true,
      //指令下发
      contentTypeList: [],//指令下发内容标识集合

      deviceCommands: [], // 通过类型id查到的指令集合

      userRightList: [],

      deviceeIcon: '',
    };
  },
  created() {
    this.getUsers()
    this.getDeviceTypeList();
  },

  methods: {
    checkLinkage() {
      if (this.showLinkage) {
        this.showLinkage = false
      } else {
        this.showLinkage = true
      }
    },
    removeLinkage() {
      this.conditionsTypeList.pop()
    },
    addLinkage() {
      if (this.conditionsType == '') {
        this.$message.warning('联动类型不能为空！')
      } else {
        this.conditionsTypeList.push(this.conditionsType)
        this.conditionsType = ''
      }

    },

    goSelect() {
      this.$router.push("/Select");
    },
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('设备类型编号')
      } else {
        this.tableTitle = value
      }
    },
    // 获取用户权限
    async getUserRight(user) {
      this.userRightList = []
      const { data: res } = await LoginUserJuris.getMenuFromUserName(user)
      this.userRightList = res.data.jurisList
      if (this.userRightList.length == 0) {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      } else {
        for (let i = 0; i < res.data.jurisList.length; i++) {
          if (res.data.jurisList[i].menuName == "deviceInfoManagement") {
            this.userRightList = res.data.jurisList[i]
          }
        }
      }
      console.log(this.userRightList);
      if (user == "admin") {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      }

    },
    // 获取用户名
    getUsers() {
      user.loginUserInfo().then(res => {
        this.getUserRight(res.data.data.userName)
      })
    },

    // 提交指令数据
    submit() {
      let isUpdate = false
      console.log(this.contentTypeList);
      console.log(this.deviceCommands);
      if (this.contentTypeList.length == this.deviceCommands.length) {
        if (this.contentTypeList.length == 0) {
          this.$message.success('修改成功！')
          this.showOrder = false
        } else {
          isUpdate = true
        }
      } else {
        if (this.deviceCommands.length == 0) {
          OperationalOrders.deleteCommand(this.deviceTypeInfoList.id).then(res => {
            if (res.data.message == "删除成功") {
              this.$message.success("修改成功！")
              this.showOrder = false
              this.getDeviceTypeList();
            } else {
              this.$message.warning(res.data.message)
            }
          })
        } else {
          isUpdate = true
        }
      }
      if (isUpdate) {
        OperationalOrders.updateCommand(this.deviceCommands).then(res => {
          console.log(res);
          if (res.data.message == "添加成功") {
            this.$message.success("修改成功！")
            this.showOrder = false
            this.getDeviceTypeList();
          } else {
            this.$message.warning(res.data.message)
          }
        })
      }
    },

    // 指令操作
    async instruct(data) {
      this.deviceTypeInfoList = data
      const { data: res } = await OperationalOrders.getCommandByTypeId(this.deviceTypeInfoList.id, null)
      this.deviceCommands = res.data.deviceCommands
      this.contentTypeList = JSON.parse(JSON.stringify(res.data.deviceCommands))
      // 刷新子组件传参
      this.timer = new Date().getTime()
      this.showOrder = true
    },

    // 获取子组件中指令集合
    orderList(data) {
      console.log(data);
      this.deviceCommands = data
      // console.log(this.contentTypeList);
      // if (this.contentTypeList.length != 0) {
      //   this.submitTip = false
      // } else {
      //   this.submitTip = true
      // }
    },
    // 获取子组件数据
    // cleatList(val) {
    //   console.log(val);
    //   if (val) {
    //     this.contentTypeList = []
    //     if (this.contentTypeList.length != 0) {
    //       this.submitTip = false
    //     } else {
    //       this.submitTip = true
    //     }
    //   }
    // },

    // 关闭弹框
    closeShowOrder() {
      this.showOrder = false
    },
    //验证
    beforeAvatarUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isSVG = file.type === "image/svg+xml";
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!(isJPG || isPNG || isSVG || isJPEG)) {
        this.$message.error("上传图标只能是 JPG、PNG或SVG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图标大小不能超过 1MB!");
        return (isJPG || isPNG || isSVG) && isLt2M;
      }
    },
    //上傳设备类型图标文件选择后触发
    fileChange(e) {
      let files = e.target.files[0]; // 获取上传内容
      this.file = files;

      let fileReader = new FileReader();
      let type = "default";

      this.beforeAvatarUpload(files);
      //判断文件类型
      if (/image/.test(files.type)) {
        fileReader.readAsDataURL(files);
        type = "image";
      } else {
        fileReader.readAsText(files, "utf-8");
        type = "text";
      }
      //文件加载出错
      fileReader.onerror = function (err) {
        console.log(err);
      };
      fileReader.onload = () => {
        this.deviceTypeInfo.deviceTypeIcon = fileReader.result;
        this.imgFlag = true;
      };
    },

    //多条件分页查询设备类型
    getDeviceTypeList() {
      var that = this;
      that.deviceTypeQuery.name = that.deviceTypeInfo.name;
      deviceType
        .getDeviceTypeList(this.current, this.limit, this.deviceTypeQuery)
        .then((res) => {
          this.total = res.data.data.total;
          this.deviceTypeList = res.data.data.rows;
          if (res.data.success) {
            this.loading = false;
          }
        });
    },

    cancleDialog() {
      this.dialogVisible = false;
      if (this.dialogVisible == false) {
        this.showCommand = false;
      }
    },

    //添加或者编辑设备类型信息
    addOrEditDeviceType() {
      if (this.addOrEditFlag == "add") {
        if (!this.file.size) {
          this.$message.error("请选择图标！");
        }
      }

      if (this.deviceTypeInfo.id == "") {
        this.$message.error("请填写设备类型ID！");
      } else if (this.deviceTypeInfo.name == "") {
        this.$message.error("请填写设备类型名称！");
      } else if (this.deviceTypeInfo.properties.length == 0) {
        this.$message.error("请填写设备类型静态属性！");
      }

      // else if (this.deviceTypeInfo.val.length == 0) {
      //     this.$message.error("请填写设备类型动态属性！");
      // }
      else {
        //判断是添加还是编辑框

        if (this.deviceTypeInfo.id.length > 2 && "GR" == this.deviceTypeInfo.id.substring(this.deviceTypeInfo.id.length - 2)) {

          let wechatTzb = false;
          let DXTzb = false;
          let PHONETzb = false;
          let phonesb = false;

          for (let i in this.deviceTypeInfo.properties) {
            if (this.deviceTypeInfo.properties[i] == "微信通知") {
              wechatTzb = true;

            } else if (this.deviceTypeInfo.properties[i] == "短信通知") {
              DXTzb = true

            } else if (this.deviceTypeInfo.properties[i] == "语音通知") {
              PHONETzb = true

            } else if (this.deviceTypeInfo.properties[i] == "通知电话") {
              phonesb = true
            }
          }

          if (!wechatTzb)
            this.deviceTypeInfo.properties.push("微信通知")
          if (!DXTzb)
            this.deviceTypeInfo.properties.push("短信通知")
          if (!PHONETzb)
            this.deviceTypeInfo.properties.push("语音通知")
          if (!phonesb)
            this.deviceTypeInfo.properties.push("通知电话")
        }

        if (this.addOrEditFlag == "add") {

          //实例化FormData对象用于传输图片文件和图片名称
          const formData = new FormData();
          formData.append("multipartFile", this.file);
          formData.append("id", this.deviceTypeInfo.id);
          formData.append("name", this.deviceTypeInfo.name);
          formData.append("properties", this.deviceTypeInfo.properties);
          formData.append("val", this.deviceTypeInfo.val);

          formData.append("productId", this.deviceTypeInfo.productId);
          formData.append("masterAPIkey", this.deviceTypeInfo.masterAPIkey);
          formData.append("conditionsType", this.conditionsTypeList);

          deviceType.addDeviceType(formData).then((res) => {
            if (res.data.success) {
              //提示信息
              this.$message({
                type: "success",
                message: res.data.message,
              });
              //回到页面并刷新
              this.conditionsTypeList = []
              this.dialogVisible = false; //关闭弹窗
              this.clearDeviceTypeInfo(); //清空设备类型信息对象数据
              this.getDeviceTypeList(); //刷新页面数据
            } else {
              //提示信息
              this.$message({
                type: "warning",
                message: res.data.message,
              });
            }
          });
        } else {
          const formData = new FormData();
          formData.append("multipartFile", this.file);
          formData.append("id", this.deviceTypeInfo.id);
          formData.append("name", this.deviceTypeInfo.name);
          formData.append("properties", this.deviceTypeInfo.properties);
          formData.append("val", this.deviceTypeInfo.val);
          formData.append("deviceTypeIcon", this.deviceTypeInfo.deviceTypeIcon);
          formData.append("productId", this.deviceTypeInfo.productId);
          formData.append("masterAPIkey", this.deviceTypeInfo.masterAPIkey);
          formData.append("conditionsType", this.conditionsTypeList);
          console.log(this.conditionsTypeList);
          deviceType.updateDeviceType(formData).then((res) => {
            if (res.data.success) {
              //提示信息
              this.$message({
                type: "success",
                message: res.data.message,
              });
              //回到页面并刷新
              this.conditionsTypeList = []
              this.dialogVisible = false; //关闭弹窗
              this.clearDeviceTypeInfo(); //清空设备类型信息对象数据
              this.getDeviceTypeList(); //刷新页面数据
            } else {
              //提示信息
              this.$message({
                type: "warning",
                message: res.data.message,
              });
            }
          });
        }
      }
      this.showCommand = false;
    },

    //根据设别类型id删除设备类型信息
    handleRemove(id) {
      deviceType.getDeviceInfoByTypeId(id).then((res) => {
        if (res.data.success) {
          this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            //点击确定，指定then方法
            deviceType.removeDeviceType(id).then((res) => {
              //提示信息
              this.$message({
                type: res.data.success ? "success" : "warning",
                message: res.data.message,
              });
              //回到页面并刷新
              this.getDeviceTypeList();
            });
          });
        } else {
          //提示信息
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 5000,
          });
        }
      });
    },

    //添加设备类型信息弹框
    handelAdd() {
      this.flag = false;
      this.showLinkage = false;
      this.conditionsTypeList = [];
      this.dialogVisible = true;
      this.addOrEditFlag = "add";
    },

    //修改设备类型信息弹框数据加载
    handleEdit(row) {
      console.log(row);
      this.deviceeIcon = BaseData.baseUrl + 'deviceTypeIcon/无线物联网4G中继器手动报警设备.png'
      console.log(this.deviceeIcon);
      if (row.conditionsType.length > 0) {
        this.showLinkage = true
        this.conditionsTypeList = row.conditionsType
      } else {
        this.showLinkage = false
        this.conditionsTypeList = []
      }
      this.flag = true;
      this.deviceTypeTitle = row.name;
      this.deviceTypeInfo = {
        id: row.id,
        name: row.name,
        properties: row.properties,
        val: row.val,
        deviceTypeIcon: row.deviceTypeIcon,
        productId: row.productId,
        masterAPIkey: row.masterAPIkey
      };
      this.dialogVisible = true;
      this.addOrEditFlag = "edit";
    },
    //追加静态属性
    addToProperties() {
      this.deviceTypeInfo.properties.push(this.propertiesNameTemp);
      this.propertiesNameTemp = "";
    },
    removeLastOneFromProperties() {
      this.deviceTypeInfo.properties.pop();
      this.propertiesNameTemp = "";
    },
    //追加动态属性
    addToVal() {
      this.deviceTypeInfo.val.push(this.valNameTemp);
      this.valNameTemp = "";
    },
    removeLastOneFromVal() {
      this.deviceTypeInfo.val.pop();
      this.valNameTemp = "";
    },

    //清空设备类型信息对象
    clearDeviceTypeInfo() {
      this.deviceTypeInfo = {
        id: "",
        name: "",
        properties: [],
        val: [],
        deviceTypeIcon: "",
        productId: "",
        masterAPIkey: ""
      };
      this.getDeviceTypeList();
      this.imgFlag = false;
      this.showCommand = false;
    },

    //返回上一级
    goBack() {
      window.history.back();
    },

    instructionsIssuedBy() {
      this.showCommand = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.rows {
  margin: 10px 10px 10px 0;
}
/deep/ .el-page-header__content {
  color: white;
}

.el-table {
  border-radius: 8px;
}
/deep/ .el-table thead {
  color: #000;
  font-weight: 700;
}
.order {
  width: 100%;
  height: 400px;
  overflow: scroll;
}
// .el-col-3 {
//   width: 10.5%;
// }
/deep/.el-dialog__title {
  font-weight: 600;
  font-size: 22px;
  color: rgb(2, 73, 50);
  letter-spacing: 2px;
}
/deep/ .el-dialog__title::before {
  content: "丨";
  font-weight: 600;
  color: rgb(2, 73, 50);
}
::-webkit-scrollbar {
  display: none;
}
.linkageBtn {
  outline: none;
}
.item {
  margin: 20px 5px;
  }
</style>
